/* ---- Contact CSS ---- */
@font-face {
    font-family: 'architect';
    src: url(../../assets/fonts/Arquitecta/Arquitecta.woff);
}

.contact_main {
    background-color: #fef0eb;
    height: 100vh;
}

.contact_box {
    /* border: 1px solid blueviolet; */
    box-shadow: rgba(134, 133, 133, 0.35) 0px 5px 10px;
    background-color: #fff;
    border-radius: 16px;
    padding: 80px 50px;
}

.contact_part {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    padding: 10px;
}

.phone_cal {
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #f05523;
    font-weight: bold;
    font-size: larger;
}

.phone_plain {
    color: #f05523;
    text-decoration: none;
}
