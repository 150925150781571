/* CustomCheckbox.css */

/* Container Label */
.custom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  color: #333;
  vertical-align: middle;
  line-height: 24px;       /* Match the new checkbox height */
  margin-top: -6px;
}

/* Hidden Native Checkbox */
.custom-checkbox input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
  position: absolute;
  left: -9999px;
}

/* Custom Checkbox */
.custom-checkbox .checkmark {
  position: relative;
  height: 24px;            /* Increased size */
  width: 24px;             /* Increased size */
  min-width: 24px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  margin-right: 8px;
  flex-shrink: 0;
}

.custom-checkbox input:checked + .checkmark {
  background-color: #2196F3;
  border-color: #2196F3;
}

/* Checkmark (Tick) */
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked + .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 7px;                  /* Adjusted position */
  top: 0.7px;                   /* Adjusted position */
  width: 8px;                 /* Adjusted size */
  height: 16px;               /* Adjusted size */
  border: solid white;
  border-width: 0 3px 3px 0;  /* Increased from 2px to 3px */
  transform: rotate(45deg);
}
