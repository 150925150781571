

.google-score .google svg {
  font-size: 32px;
  color: var(--bs-body-color);
  margin-right: 4px;
}

.google-score .star svg {
  color: #fabb05;
  font-size: 23px;
  margin-left: 3px;
}
