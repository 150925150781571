.calender_area {
    /* box-shadow: rgba(46, 32, 32, 0.35) 0px 5px 10px;
    background-color: #fee4d5; */
}
.calender_area .react-calendar {
    margin-top: 25px;
    border: none !important;
    background-color:#fee4d5 !important;
    border-radius: 15px;
    height: 185px;
    width: 420px;
    overflow: hidden;
}
.app_time_picker {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.app_time_picker

.app_time_picker
.app_circle

.app_time_picker
.app_circle

.btn_align {
    margin-left: -10px;
}
abbr {
    text-decoration: none !important;
}
.react-calendar
.react-calendar__navigation {
    display: flex;
    height: 35px;
    margin-bottom: 0em;
}
