/* ===== Loginscree Styles ==== */
@font-face {
    font-family: 'architect';
    src: url(../../assets/fonts/Arquitecta/Arquitecta.woff);
}

.email_box {
    box-shadow: rgba(134, 133, 133, 0.35) 0px 5px 10px;
    background-color: #fff;
    border-radius: 16px;
    margin: 1rem 2rem;
    padding: 2rem 2rem;
}
.border{
    max-width: 60px;
}
.schedule-h1{
    font-family: 'architect';
}
.email-h1 {
    margin: 0;
    text-align: center;
    font-size: 40px;
    font-family: 'architect';
    font-weight: bolder;
}
.email-h1 div {
    margin: 0;
    text-align: center;
    font-size: 40px;
    color: #f05523;
    font-weight: bolder;
}
.email_box .email_p, .email_box .email_p_short {
    text-align: center;
    margin-top: 38px;
    margin-bottom: 24px;
    color: #999999;
    font-size: 16px;
    font-weight: normal;
    line-height:1.3;
}


strong {
    color: #737072;
    font-family: 'architect';
    font-weight: bold;
}

span {
    color: #f05523;
}
h6 {
    margin: 0px;
}
.put-email {
    display: flex;
    flex-direction: column;
}
.email_input {
    margin-left: 20px;
    color: #000;
    margin-top: 18px;
    font-weight: 500;
    margin-bottom: -3px;
}

.email_btn {
    background-color: #f05523;
    border: none;
    border-radius: 50px;
    margin-top: 28px;
    color: #fff;
    font-size: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-family: 'architect';
}
.email_btn:hover {
    opacity: 0.8;
    color: white;
  }
  .main_pad{
      display: flex !important;
  }

  @media (max-width: 400px ) {
     .email-h1 div {
         font-size: 26px;
     }
  }
