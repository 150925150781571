.form-box-container {
  background-color: var(--body-background);
  padding-left: 10px;
  padding-right: 10px;
}

.form-box {
  background-color: var(--secondary-background);
  box-shadow: var(--shadow-color) 0px 5px 10px;
  border-radius: 16px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  display: block;
  text-align: center;
  width: calc(100% - 10px);
  max-width: 680px;
  padding-bottom: 20px;
}

.form-box h1 {
  margin-top: 8px;
  margin-bottom: 4px;
}

.form-box form {
  padding-left: 8px;
  padding-right: 8px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.form-box .form-title, .form-box .form-subtitle {
  margin-left: 8px;
  margin-right: 8px;
}

.form-box .put-email {
  margin-top: -2px;
}

.form-box textarea {
  height: 140px;
}

.form-box button {
  width: calc(100% - 20px);
}
