/* ===== Common Styles ==== */
@font-face {
    font-family: 'architect';
    src: url(../assets/fonts/Arquitecta/Arquitecta.woff);
}

.circle-check-disabled-svg {
    fill: #c0c0c0;
    width: 24px;
}
.circle-check-primary-svg {
    fill: #f05523;
    width: 24px;
}
.primary-svg {
    min-width: 38px;
    fill: #F05523;
}
.row{
    padding: 0 !important;
}
.centerme {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.logo {
    height: 54px;
    width: auto;
}

.email_box{
    max-height: 678px;
    height: calc(100% - 40px);
}

.email_bg_img {
    width: 100%;
    height: auto;
}

.main_bg {
    background-color: #fef0eb;
}

.main_pad {
    padding: 4rem 0rem;
}

.dot_area {
    display: flex;
    margin-top: 15px;
    align-items: baseline;
}

.dot {
    height: 8px;
    width: 8px;
    background-color: #f05523;
    border-radius: 50%;
    display: inline-block;
}

.dot_p {
    margin-left: 10px;
}

.schedule-h1 {
    font-size: 28px;
    margin-top: 19px;
    font-family: 'architect';
    font-weight: bolder;
}

p {
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 5px;
    color: #737072;
    margin-left: 5px;
    font-family: 'architect';
    font-weight: 600;
}

.no-cost_text {
    margin-top: 25px;
}

.copyright_p {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 0px;
}

.field_area {
    border-radius: 35px;
    margin-top: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 1px solid #f05523;
    border-width: 1px;
    padding-left: 16px;
    opacity: 0.6;
}
.drop-down-area {
    padding-left: 4px;
    padding-right: 6px;
    padding-top: 6px;
}

.field_area.error {
    border: 2px solid #be2314 !important;
    border-width: 2px;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 15px;
    background-color: #fff3f3;
}

.check_mark_main {
    display: flex;
    justify-content: space-evenly;
    margin-top: 22px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: -12px;
}

.check_mark_main p {
    margin-top: 10px;
}

.schedule_box {
    box-shadow: rgba(134, 133, 133, 0.35) 0px 5px 10px;
    background-color: #fff;
    border-radius: 16px;
    margin: 1rem 2rem;
    padding: 3rem 2rem;
    padding-bottom: 70px;
    max-height: 678px;
    height: calc(100vh - 120px);
}
.calender_area{
    visibility: visible !important;
    overflow-y: auto;
    height: 220px;
}
@media screen and (max-width: 480px) {
    .main_bg .email_box, .main_bg .reg_box {
        margin-left: 18px;
        margin-right: 18px;
    }
}
@media screen and (max-width: 430px) {
    .main_bg .email_box, .main_bg .reg_box {
        padding: 18px 12px 18px 12px;
    }
    .email_box p.email_p, .email_box p.email_p_short {
        margin-top: 21px;
        margin-bottom: 13px;
    }
    .email_box button.email_btn {
        margin-top: 18px;
    }
}
@media screen and (max-height: 728px) {
    .check_mark_main {
        margin-top: 8px;
        margin-bottom: -18px;
    }
}
@media screen and (max-height: 720px) {
    .calender_area {
        margin-top: -20px;
        margin-bottom: -24px;
    }
    .copyright_p {
        display: none;
    }
    .main_pad .mt-3 {
        margin-top: 0px !important;
    }
    .email_box .email_p, .email_box .email_p_short {
        margin-top: 20px !important;
        margin-bottom: 14px !important;
    }
    .reg_box .reg_input {
        margin-top: 10px;
    }
    .schedule_box {
        height: calc(100vh - 34px);
    }
}
.email_p_short {
    display: none;
}
@media screen and (max-height: 596px) {
    .check_mark_main {
        margin-top: 4px;
        margin-bottom: -22px;
    }
}
@media screen and (max-height: 588px) {
    .calender_area {
        margin-top: -23px;
        margin-bottom: -24px;
        height: 206px;
    }
    .reg_box {
        margin-top: 8px !important;
        margin-bottom: 0px !important;
    }
    .email_p {
        display: none;
    }
    .email_p_short {
        display: inline-block;
    }
    .main_pad .email_box {
        padding-bottom: 14px;
        padding-top: 15px;
    }
    .email_box .email_btn {
        margin-top: 22px;
    }
    .email_box .email_list {
        margin-bottom: -8px;
    }
}
@media screen and (max-height: 537px) {
    .reg_box h1.reg_main_h1 {
        display: none;
    }
    .reg_box .reg_input {
        margin-bottom: -8px;
        margin-top: 4px;
    }
    .check_mark_main {
        display: none;
    }
    .reg_box .react_calender_container {
        margin-top: -8px;
    }
    .reg_box .calender_area {
        height: 180px;
    }
    .schedule_box .calendar_black {
        display: none;
    }
}
@media screen and (max-width: 430px) {
    .main_bg .email_box, .main_bg .reg_box {
        margin-left: 6px;
        margin-right: 6px;
    }
}

.product_box .product_btn {
    width: calc(100% - 60px);
    margin-top: 16px;
}
@media screen and (max-width: 380px) {
    .product_box .product_btn {
        width: calc(100% - 20px);
    }
}
@media screen and (max-width: 280px) {
    .product_box .product_btn {
        width: calc(100% - 10px);
    }
}
.product_box {
    overflow-y: auto;
}

/*:::::::::::::::Chris Styles:::::::::::::::::::::*/
.hideme { display:none; }

@media screen and (max-width: 768px) {
    /*  .centerme { background-color: olive; } */
    .row { --bs-gutter-x: 0rem!important; }
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl { padding: 0px!important; }
    .email_box, .reg_box, .schedule_box { margin: 5px; padding: 10px; padding-bottom: 16px; }
}
@media screen and (min-width: 769px) {
    .reg_box {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
@media screen and (min-height: 555px) and (min-width: 769px) {
    .reg_box {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.row.main_pad .centerme {
    max-width: 696px;
}

.time-zone-text {
    margin-top: -24px;
    margin-bottom: 24px;
    font-family: 'architect';
    font-weight: bold;
}

body #root .multi-select-product .circle-check-primary-svg {
    fill: var(--primary-text);
    opacity: 0.9;
}

.multi-select-product {
    position: relative;
    z-index: 1;
    left: 18px;
    bottom: 3px;
    margin-right: -24px;
    cursor: pointer;
}

body #root .reg_box .product_box .email_btn.product-confirm {
    width: calc(100% - 60px);
}

.powered-by-heavyset {
    margin-top: -14px;
}
.powered-by-heavyset a {
    color: var(--secondary-outline);
    text-decoration: none;
    font-size: 12px;
}
.powered-by-heavyset img {
    height: 17px;
    margin-top: -3px;
}
.email_box h1.email-h1 {
    margin-top: -1px;
}

.opt-in-check-default {
    display: inline-block;
    vertical-align: bottom;
    margin-top: -2px;
}
.opt-in-check-true {
    display: none;
}

.enter_home_year .reg_box, .enter_details .reg_box {
    padding-top: 12px;
}
.enter_home_year .reg_box .product_box input {
    max-width: calc(100% - 70px);
}
.home-year-inputs {
    width: calc(100% - 60px);
    margin-left: auto;
    margin-right: auto;
}

.toggle-phone-email-container {
    display: flex;
    width: 100%;
    justify-content: right;
}
.toggle-phone-email {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Slightly larger for better clickability */
    height: 40px;
    border-radius: 6px; /* Slight rounding for button-like feel */
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.1s ease;
}

.toggle-phone-email:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Lighten the background on hover */
}

.toggle-phone-email:active {
    transform: scale(0.95); /* Slight press effect */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* Adds depth */
}

.toggle-phone-email svg {
    width: 36px;
    height: 36px;
    transition: fill 0.2s ease;
}

.toggle-phone-email:hover svg {
    fill: #007bff; /* Optional: Change icon color on hover */
}

ul.google-suggestions {
    list-style: none;
    margin: 0;
    padding: 4px;
    border: 1px solid var(--disclaimer-text);
    color: var(--primary-background);
    background: var(--primary-text);
}
li.address-manual-text {
    cursor: pointer;
    padding: 6px 8px;
    font-style: italic;
    font-size: 17px;
    color: var(--primary-background);
    background: var(--primary-text);
}
li.address-auto-text {
    cursor: pointer;
    padding: 6px 8px;
}
li.address-auto-text.is-active {
    background-color: #eee;
}
li.address-auto-text strong {
    font-size: 16px;
}
li.address-auto-text span {
    font-size: 14px;
}

.divider-img {
    width: 100%;
    text-align: center;
}

.confirm-embed-video {
    width: 560px;
    height: 315px;
    max-width: 100%;
}
